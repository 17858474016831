import { UserMin } from "src/types/model";
import { UserPayload, UserWithBlockedList } from "src/types/query";

import BaseAPIService from "./baseAPIService";

export default class UserService extends BaseAPIService {
  static getMe = async (): Promise<UserMin> =>
    this.requestWithAuth(`/user/me`, "GET");

  static getUserByID = async (
    id: number | string
  ): Promise<UserWithBlockedList> => this.requestWithAuth(`/user/${id}`, "GET");

  static updateUser = async (
    userID: number,
    payload: UserPayload
  ): Promise<UserWithBlockedList> =>
    this.requestWithAuth(`/user/${userID}`, "PUT", payload);
}
