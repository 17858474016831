import { UserPaletteRaw } from "src/types/model";
import { UserPalettePayload } from "src/types/query";

import BaseAPIService from "./baseAPIService";

export default class UserPaletteService extends BaseAPIService {
  static getPaletteByUserID = async (userID: number): Promise<UserPaletteRaw> =>
    this.requestWithAuth(`/palette/${userID}`, "GET");

  static updatePalette = async (
    id: number,
    payload: UserPalettePayload
  ): Promise<UserPaletteRaw> =>
    this.requestWithAuth(`/palette/${id}`, "PUT", payload);

  static deletePalette = async (id: number) =>
    this.requestWithAuth(`/palette/${id}`, "DELETE");
}
