import { CssBaseline, withWidth } from "@material-ui/core";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import config from "src/config";
import { withEmergencyShutdown } from "src/hooks";
import { withMessage } from "src/hooks/withMessage";
import { RootState } from "src/redux";

import { GlobalStyle, Root } from "./Main.style";

const InnerMain: React.FC = withEmergencyShutdown(
  withMessage(({ children }) => {
    const user = useSelector((state: RootState) => state.authReducer.user);
    useEffect(() => {
      if (!user) {
        window.location.href = config.parentAppURL + "/login";
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
      <Root>
        <CssBaseline />
        <GlobalStyle />
        {children}
      </Root>
    );
  })
);

export const Main = withWidth()(InnerMain);
